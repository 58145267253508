<template>
  <div>
   <v-card class="elevation-0">
     <v-card-text>
       <v-row>
         <v-col>
           <h2>ແຜນຕາຕະລາງຈັດສົ່ງນ້ຳ</h2>
         </v-col>
         <v-col class="align-end">
           <v-btn class="btn-primary mb-4 elevation-0" medium dark @click="OpenModalAdd()">
             <v-icon>mdi-plus</v-icon> ເພີ່ມເວລາຈັດສົ່ງ
           </v-btn>
         </v-col>

       </v-row>

       <v-sheet outlined color="grey" rounded>
         <v-card class="elevation-0 outlined">
           <v-card-text class="pb-1">
             <v-row>
             <v-col
             >
               <v-menu
                   ref="filter_menu"
                   v-model="filter_menu"
                   :close-on-content-click="false"
                   :nudge-right="40"
                   transition="scale-transition"
                   offset-y
                   min-width="auto"
                   persistent
               >
                 <template v-slot:activator="{ on, attrs }">
                   <v-combobox
                       v-model="filterDates"
                       multiple
                       chips
                       small-chips
                       label="ເລືອກວັນທີ"
                       prepend-icon="mdi-calendar"
                       readonly
                       v-bind="attrs"
                       v-on="on"
                       outlined
                       dense
                   ></v-combobox>
                 </template>
                 <v-date-picker
                     v-model="filterDates"
                     multiple
                     scrollable
                 >
                   <v-spacer></v-spacer>
                   <v-btn
                       text
                       color="primary"
                       @click="filter_menu = false"
                   >
                     Cancel
                   </v-btn>
                   <v-btn
                       text
                       color="primary"
                       @click="SelectedDates"
                   >
                     OK
                   </v-btn>
                 </v-date-picker>
               </v-menu>
             </v-col>
             <v-col class="d-flex">
               <v-autocomplete
                   v-model="filterDay"
                   :items="day"
                   label="ວັນ"
                   item-text="name"
                   item-value="id"
                   dense
                   outlined
                   clearable
               ></v-autocomplete>
             </v-col>
               <v-col class="d-flex">
                 <v-select
                     v-model="selectedType"
                     :items="type"
                     label="ປະເພດແຜນ"
                     item-text="name"
                     item-value="key"
                     dense
                     outlined
                     clearable
                 ></v-select>
               </v-col>
             <v-col class="d-flex" v-if="selectedType == 'truck'">
               <v-select
                   v-model="selectedVehicle"
                   :items="trucks"
                   label="ເລືອກລົດ"
                   item-text="license_plate"
                   item-value="id"
                   dense
                   outlined
                   clearable
               ></v-select>
             </v-col>
               <v-col class="d-flex" v-if="selectedType == 'franchise'">
                 <v-select
                     v-model="selectedBranch"
                     :items="branches"
                     label="ສາຂາ"
                     item-text="name"
                     item-value="id"
                     dense
                     outlined
                     clearable
                 ></v-select>
               </v-col>
             <v-col class="d-flex">
               <v-autocomplete
                   v-model="filterPlan"
                   :items="plans"
                   label="ແຜນຈັດສົ່ງ"
                   item-text="name"
                   item-value="id"
                   dense
                   outlined
                   clearable
               ></v-autocomplete>
             </v-col>

         <!--      <v-col>-->
         <!--        <v-text-field-->
         <!--            outlined-->
         <!--            dense-->
         <!--            clearable-->
         <!--            prepend-inner-icon="mdi-account-search-outline"-->
         <!--            label="ເລກບິນ,ຊື່ລູກຄ້າ,ເບີໂທ"-->
         <!--            type="text"-->
         <!--            v-model="search"-->
         <!--            @keyup.enter="Search()"-->
         <!--        >-->
         <!--        </v-text-field>-->
         <!--      </v-col>-->
       </v-row>
           </v-card-text>
         </v-card>
       </v-sheet>
       <v-data-table
           :headers="headers"
           :items="calendars"
           :disable-pagination="true"
           hide-default-footer
           :loading="TableLoading"
           :disabled="TableLoading"
           loading-text="Loading... Please wait"
           class="elevation-0"
       >
         <template v-slot:item.name="{ item }">
           <v-chip label color="success">
             {{ item.planName}}
           </v-chip>
         </template>
         <template v-slot:item.total="{ item }">
           <v-chip color="info">
             {{ item.total}}
           </v-chip>
         </template>
         <template v-slot:item.created_at="{ item }">
           <div>
             {{ item.created_at | formatDate }}
           </div>
         </template>
         <!--Action -->
         <template v-slot:item.actions="{ item }">
           <v-icon small class="mr-2" @click="ViewRoutePlan(item.id)">
             mdi-eye
           </v-icon> <v-icon small class="mr-2" @click="OpenModalEdit(item)">
           mdi-pencil
         </v-icon>
           <v-icon small @click="deleteItem(item.id)" class="mr-2"> mdi-delete </v-icon>
           <v-icon small @click="exportCalendarPdf(item)"> mdi-download</v-icon>
         </template>
       </v-data-table>
       <br />
       <template>
         <Pagination
             v-if="pagination.last_page > 1"
             :pagination="pagination"
             :offset="offset"
             @paginate="fetchData()"
         ></Pagination>
       </template>
     </v-card-text>
   </v-card>

    <!--Delete Modal-->
    <ModalAdd>
      <template @close="close">
        <v-card>
          <v-card-title>
            <span>ເພີ່ມຕາຕະລາງມື້ຈັດສົ່ງ</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-select
                        required
                        v-model="selectedDay"
                        :items="day"
                        item-text="name"
                        item-value="id"
                        label="ວັນ *"
                    >
                    </v-select>
                    <p class="errors">
                      {{ server_errors.plan_day_id }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-combobox
                            v-model="dates"
                            multiple
                            chips
                            small-chips
                            label="ເລືອກວັນທີ"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-combobox>
                      </template>
                      <v-date-picker
                          v-model="dates"
                          multiple
                          no-title
                          scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">
                          Cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.menu.save(dates)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                    <p class="errors">
                      {{ server_errors.date }}
                    </p>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col col="12">
                      <v-select
                          v-model="selectedType"
                          :items="type"
                          label="ປະເພດແຜນ"
                          item-text="name"
                          item-value="key"
                          dense
                      ></v-select>
                  </v-col>
                </v-row>
                <v-row v-if="selectedType == 'truck'">
                  <v-col cols="12">
                    <v-autocomplete
                        required
                        v-model="selectedTruck"
                        :items="trucks"
                        item-text="license_plate"
                        item-value="id"
                        label="ລົດຈັດສົ່ງ *"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>

                <v-row v-if="selectedType == 'franchise'">
                  <v-col cols="12">
                    <v-autocomplete
                        required
                        v-model="selectedBranch"
                        :items="branches"
                        item-text="name"
                        item-value="id"
                        label="ເລືອກສາຂາ*"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                        required
                        v-model="selectedPlan"
                        :items="plans"
                        :item-text="getPlan"
                        item-value="id"
                        label="ແຜນເສັ້ນທາງ *"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="CloseModalAdd()">
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              :disabled="loading"
              @click="AddItem()"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ModalAdd>

    <!--Edit Modal-->
    <ModalEdit>
      <template @close="close">
        <v-card>
          <v-card-title>
            <span class="headline">Update Calendar</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-menu
                        v-model="edit_menu"
                        :close-on-content-click="true"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        ref="edit_menu"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="edit_calendar.date"
                            label="ວັນທີຢູ່ໃນແຜນ"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                            dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="edit_calendar.date"
                      ></v-date-picker>
                    </v-menu>
                    <p class="errors">
                      {{ server_errors.date }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-select
                        required
                        v-model="edit_calendar.plan_day_id"
                        :items="day"
                        item-text="name"
                        item-value="id"
                        label="Day *"
                    >
                    </v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col col="12">
                    <v-select
                        v-model="edit_calendar.type"
                        :items="type"
                        label="ປະເພດແຜນ"
                        item-text="name"
                        item-value="key"
                        dense
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row v-if="edit_calendar.type == 'truck'">
                  <v-col cols="12">
                    <v-autocomplete
                        required
                        v-model="edit_calendar.truck_id"
                        :items="trucks"
                        item-text="license_plate"
                        item-value="id"
                        label="ລົດຈັດສົ່ງ *"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>

                <v-row v-if="edit_calendar.type == 'franchise'">
                  <v-col cols="12">
                    <v-autocomplete
                        required
                        v-model="edit_calendar.branch_id"
                        :items="branches"
                        item-text="name"
                        item-value="id"
                        label="ເລືອກສາຂາ*"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                        required
                        v-model="edit_calendar.route_plan_id"
                        :items="plans"
                        :item-text="getPlan"
                        item-value="id"
                        label="Route Plan *"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="CloseModalEdit()">
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              :disabled="loading"
              @click="UpdateItem()"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ModalEdit>

    <!--Delete Modal-->
    <ModalDelete>
      <template>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete()"
            >Cancel</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            :loading="loading"
            :disabled="loading"
            @click="deleteItemConfirm()"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </template>
    </ModalDelete>
  </div>
</template>

<script>
// import manage from "@/mixins/routeplan/calendar";
import Pagination from "../../../plugins/pagination/pagination";
import queryOption from "../../../Helpers/queryOption";
export default {
  // mixins: [manage],
  components: {
    Pagination,
  },
  data() {
    return {
      //Pagination
      offset: 15,
      pagination: {},
      per_page: 300,
      selectedVehicle:'',

      loading: false,
      TableLoading: false,
      server_errors: {
        truck_id: '',
        phone: ''
      },
      calendar: {},
      trucks: [],
      selectedTruck: '',
      plans: [],
      selectedPlan: '',
      filterPlan: '',
      day: [],
      filterDay: '',
      selectedDay: '',
      dates: [new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)],
      menu: false,

      filterDates: [new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)],
      filter_menu: false,

      edit_menu: false,
      edit_calendar: {},
      calendars: [],
      type: [
        {
          id: 1,
          name: "ສາຂາແຟຣນຊາຍ",
          key: "franchise"
        } ,
        {
          id: 2,
          name: "ລົດຂົນສົ່ງ",
          key: "truck"
        }
      ],
      selectedType:"",
      selectedBranch:"",
      branches: [],

      headers: [
        {
          text: "ວັນທີ",
          align: "start",
          sortable: false,
          value: "date",
        },
        {
          text: "ວັນ",
          align: "start",
          sortable: false,
          value: "day",
        },
        {
          text: "ຊື່ແຜນ",
          align: "start",
          sortable: false,
          value: "name",
        },
        {text: "ບ່ອນຈັດສົ່ງ", value: "total",align:"center"},
        {text: "ປະເພດ", value: "type"},
        {text: "ລົດຈັດສົ່ງ", value: "license_plate"},
        {text: "ລົດຈັດສົ່ງ", value: "branch_name"},
        {text: "ວັນທີສ້າງ", value: "created_at"},
        {text: "Actions", value: "actions", sortable: false},
      ],
      idRules: [
        v => !!v || 'Truck ID is required',
        v => (v && v.length >= 2) || 'Truck ID must be more than 5 characters',
      ],
      phoneRules: [
        (v) => !!v || "Phone is required",
        v => (v && v.length >= 4 && v.length <= 11) || 'Phone number must be  4 - 11 numbers',
      ],

      toast: {
        value: true,
        color: 'success',
        msg: 'Success'
      },
      toast_error: {
        value: true,
        color: 'error',
        msg: 'Something when wrong!'
      }
    }
  },
  metaInfo() {
    return {
      title: `ແຜນເວລາຈັດສົ່ງ`,
    }
  },
  methods: {
    OpenModalAdd() {
      this.$store.commit("modalAdd_State", true);
    },
    AddItem() {
      if (this.$refs.form.validate() == true) {
        this.Submit();
      }
    },

    Submit() {
      const data = {
        plan_day_id: this.selectedDay,
        dates: this.dates,
        truck_id: this.selectedTruck,
        route_plan_id: this.selectedPlan,
        type: this.selectedType,
        branch_id: this.selectedBranch,
      }
      this.loading = true;
      this.$admin.post('plan-calendar', data)
          .then(res => {
            if (res.data.success == true) {
              setTimeout(() => {
                this.loading = false;
                this.CloseModalAdd();
                this.truck = {},
                    this.fetchData();
                this.reset();
                this.$store.commit("Toast_State", this.toast);
              }, 1000);
            }
          }).catch(error => {
        this.loading = false;
        this.$store.commit("Toast_State", this.toast_error);
        if (error.response.status == 422) {
          let obj = error.response.data.errors;
          for (let [key, truck] of Object.entries(obj)) {
            this.server_errors[key] = truck[0];
          }
        }
      });
    },

    fetchDriver() {
      this.$admin.get('list-water-truck').then(res => {
        setTimeout(() => {
          this.trucks = res.data.trucks;
        }, 100);
      }).catch(() => {
        this.$store.commit("Toast_State", this.toast_error);
      });
    },
    fetchBranch() {
      this.$admin.get('list-branch').then(res => {
          this.branches = res.data.branches;
          let type = [
            "franchise"
          ];
          this.branches = res.data.branches.filter(item => type.includes(item.type));
      }).catch(() => {
        this.$store.commit("Toast_State", this.toast_error);
      });
    },
    fetchRoutePlan() {
      this.TableLoading = true;
      this.$admin.get('route-plan',{
        params: queryOption([
          { page: this.pagination.current_page },
          { per_page: this.per_page },
        ]),
      }).then(res => {
          this.plans = res.data.data.data;
          this.TableLoading = false;
      }).catch(() => {
        this.TableLoading = false;
        this.$store.commit("Toast_State", this.toast_error);
      });
    },
    fetchDay() {
      this.TableLoading = true;
      this.$admin.get('plan-day').then(res => {
        setTimeout(() => {
          this.day = res.data.data;
          this.TableLoading = false;
        }, 100);
      }).catch(() => {
        this.TableLoading = false;
        this.$store.commit("Toast_State", this.toast_error);
      });
    },
    CloseModalAdd() {
      this.truck = {},
          this.reset();
      this.$store.commit("modalAdd_State", false);
    },
    OpenModalEdit(item) {
      this.edit_calendar = item;
      this.$store.commit("modalEdit_State", true);
    },

    UpdateItem() {
      if (this.$refs.form.validate() == true) {
        this.loading = true;
        this.$admin.put('plan-calendar/' + this.edit_calendar.id, {
          plan_day_id: this.edit_calendar.plan_day_id,
          date: this.edit_calendar.date,
          truck_id: this.edit_calendar.truck_id,
          route_plan_id: this.edit_calendar.route_plan_id,
          type: this.edit_calendar.type,
          branch_id: this.edit_calendar.branch_id,
        })
            .then(res => {
              if (res.data.success == true) {
                setTimeout(() => {
                  this.loading = false;
                  this.CloseModalEdit();
                  this.truck_edit = {};
                  this.reset();
                  this.fetchData();
                  this.$store.commit("Toast_State", this.toast);
                }, 300);
              }
            }).catch(error => {
          this.loading = false;
          this.$store.commit("Toast_State", this.toast_error);
          this.fetchData();
          if (error.response.status == 422) {
            let obj = error.response.data.errors;
            for (let [key, truck] of Object.entries(obj)) {
              this.server_errors[key] = truck[0];
            }
          }
        });
      }
    },

    CloseModalEdit() {
      this.edit_calendar = {};
      this.reset();
      this.$store.commit("modalEdit_State", false);
    },

    fetchData() {
      this.TableLoading = true;
      this.$admin.get('plan-calendar',{
        params: queryOption([
          { page: this.pagination.current_page },
          { per_page: this.per_page },
          { dates: this.filterDates },
          { plan_day_id: this.filterDay },
          { vehicle_id: this.selectedVehicle },
          { route_plan_id: this.filterPlan },
          { branch_id: this.selectedBranch },
          { type: this.selectedType },
        ]),
      }).then(res => {
        setTimeout(() => {
          this.calendars = res.data.data.data;
          this.pagination = res.data.data;
          this.TableLoading = false;
        }, 100);
      }).catch(() => {
        this.TableLoading = false;
        this.$store.commit("Toast_State", this.toast_error);
      });
    },
    ViewRoutePlan(id) {
      this.$router.push({name: 'CalendarDetail', params: {id: id}});
    },

    closeDelete() {
      this.$store.commit("modalDelete_State", false);
    },
    deleteItem(id) {
      this.truckID = id;
      this.$store.commit("modalDelete_State", true);
    },
    deleteItemConfirm() {
      this.loading = true;
      this.$admin.delete('plan-calendar/' + this.truckID).then(res => {
        if (res.data.success == true) {
          setTimeout(() => {
            this.fetchData();
            this.$store.commit("modalDelete_State", false);
            this.loading = false;
            this.$store.commit("Toast_State", this.toast);
          }, 1000);
        }
      }).catch(() => {
        this.$store.commit("modalDelete_State", false);
        this.loading = true;
        this.$store.commit("Toast_State", this.toast_error);
      })
    },
    reset() {
      this.$refs.form.reset();
    },
    SelectedDates() {
      this.$refs.filter_menu.save();
      this.pagination = [],
          this.fetchData();
    },
    exportCalendarPdf(item) {
      this.$store.commit("progressLoading_State", true);
      this.$axios
          .get(
              "export/calendar/" + item.id, {responseType: "blob"}
          )
          .then((res) => {
            setTimeout(() => {
              const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
              const fileLink = document.createElement("a");
              fileLink.href = fileUrl;
              fileLink.setAttribute(
                  "download",
                  `${item.planName}_${item.date}_${item.license_plate}` +
                  ".xlsx"
              );
              document.body.appendChild(fileLink);
              fileLink.click();
              document.body.removeChild(fileLink);
              this.$store.commit("progressLoading_State", false);
            }, 100);
          })
          .catch(() => {
            this.$store.commit("progressLoading_State", false);
            this.$store.commit("Toast_State", this.toast_error);
          });
    },
    getPlan(value) {
      if (value.description !== null) {
        return value.name + ' ( ' + value.description +' )';
      } else {
        return value.name
      }
    },
  },
  watch: {
    filterDay: function (){
      this.pagination = [],
      this.fetchData();
    },
    selectedVehicle: function (){
      this.pagination = [],
          this.fetchData();
    },
    selectedBranch: function (){
      this.pagination = [],
          this.fetchData();
    },
    filterPlan: function (){
      this.pagination = [],
          this.fetchData();
    },

    'truck.email': function () {
      this.server_errors.email = '';
    },
    'truck.license_plate': function () {
      this.server_errors.license_plate = '';
    },
    'truck.phone': function () {
      this.server_errors.phone = '';
    },

    'truck_edit.email': function () {
      this.server_errors.email = '';
    },
    'truck_edit.license_plate': function () {
      this.server_errors.license_plate = '';
    },
    'truck_edit.phone': function () {
      this.server_errors.phone = '';
    },
    'selectedType': function (value) {
      if(value == 'franchise'){
        this.fetchBranch();
      } else if(value == 'truck'){
        this.fetchDriver();
      }
    },
    'edit_calendar.type': function (value) {
      if(value == 'franchise'){
        this.fetchBranch();
      } else if(value == 'truck'){
        this.fetchDriver();
      }
    },
  },
  created() {
    this.fetchData();
    this.fetchDay();
    this.fetchDriver();
    this.fetchRoutePlan();
  },
};
</script>

<style>
</style>